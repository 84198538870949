<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <DataTable :value="customers" :lazy="true" :paginator="true" class="p-datatable-customers" :rows="per_page" :totalRecords="totalRows" @page="onPage($event)" dataKey="id" :rowHover="true" :loading="loading" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10, 20, 30]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" ref="table">
            <template #header>
                <div class="table-header">KYC Requests</div>
            </template>
            <template #empty> No record found. </template>
            <template #loading> Loading data. Please wait. </template>

            <!-- <Column header="ID">
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            {{ slotProps.data.id }}
          </template>
        </Column> -->

            <Column header="FirstName">
                <template #body="slotProps">
                    <span class="p-column-title">FirstName</span>
                    {{ slotProps.data.first_name }}
                </template>
            </Column>

            <!-- <Column header="MiddleName">
          <template #body="slotProps">
            <span class="p-column-title">MiddleName</span>
            {{ slotProps.data.middle_name }}
          </template>
        </Column> -->

            <Column header="LastName">
                <template #body="slotProps">
                    <span class="p-column-title">LastName</span>
                    {{ slotProps.data.last_name }}
                </template>
            </Column>

            <Column header="Date Of Birth">
                <template #body="slotProps">
                    <span class="p-column-title">Date Of Birth</span>
                    <span :class="'customer-badge status-' + slotProps.data.date_birth">{{
                new Date(slotProps.data.date_birth).toLocaleDateString()
              }}</span>
                </template>
            </Column>

            <Column header="Address">
                <template #body="slotProps">
                    <span class="p-column-title">Address</span>
                    {{ slotProps.data.address }}
                </template>
            </Column>

            <Column header="Created At">
                <template #body="slotProps">
                    <span class="p-column-title">Created At</span>
                    {{
              new Date(slotProps.data.created_at).toLocaleDateString("en-US")
            }}
                </template>
            </Column>

            <Column header="Document">
                <template #body="slotProps">
                    <span class="p-column-title">Document</span>
                    <Button v-b-modal.modal-center variant="white" @click="currentRowData = slotProps.data" type="button" icon="pi pi-cog" class="btn btn-primary btn-sm w-xs">View</Button>
                </template>
            </Column>
        </DataTable>
    </div>
    <!----------------modal----------------------->
    <b-modal id="modal-center" size="xl" centered title="KYC INFORMATION" title-class="font-18" hide-footer v-if="currentRowData">
        <!-- Identity Module -->
        <b-row align-v="center">
            <h5>Identity Proof :-</h5>
            <b-col md="3">
                <div class="label_addhar_box">
                    <table>
                        <tr>
                            <td><label for="">Identity Type :</label></td>
                            <td>
                                <label for="">{{ currentRowData.identity_type }}</label>
                            </td>
                        </tr>
                        <tr>
                            <td><label for="">Identity No. :</label></td>
                            <td>
                                <label for="">{{ currentRowData.identity_number }}</label>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-col>
            <!--Label-->
            <b-col md="3">
                <div class="front_image">
                    <img v-b-modal.modal-1 @click="largeImg = currentRowData.identity_front_path" :src="currentRowData.identity_front_path" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
                </div>
            </b-col>
            <!--Font Image-->
            <b-col md="3">
                <div class="back_image">
                    <img v-b-modal.modal-1 @click="largeImg = currentRowData.identity_back_path" :src="currentRowData.identity_back_path" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
                </div>
            </b-col>
            <!--Back images-->

            <b-col md="3" v-if="currentRowData.is_identity_verify == 1">
                <span class="success">Approved</span>
            </b-col>

            <!--Back images-->
        </b-row>
        <hr />
        <!-- ------------   Adress Proof              ------------- -->
          <b-row align-v="center">
            <h5>Address Proof :-</h5>
            <b-col md="3">
                <div class="label_addhar_box">
                    <table>
                        <tr>
                            <td><label for="">Address Type :</label></td>
                            <td>
                                <label for="">{{ currentRowData.address_identity_type }}</label>
                            </td>
                        </tr>
                        <tr>
                            <td><label for="">Address:</label></td>
                            <td>
                                <label for="">{{ currentRowData.address }}</label>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-col>
            <!--Label-->
            <b-col md="3">
                <div class="front_image">
                    <img v-b-modal.modal-1 @click="largeImg = currentRowData.address_front_path" :src="currentRowData.address_front_path" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
                </div>
            </b-col>
            <!--Font Image-->
            <b-col md="3">
                <div class="back_image">
                    <img v-b-modal.modal-1 @click="largeImg = currentRowData.address_back_path" :src="currentRowData.address_back_path" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
                </div>
            </b-col>
            <!--Back images-->

            <b-col md="3" v-if="currentRowData.is_address_verify == 1">
                <span class="success">Approved</span>
            </b-col>

            <!--Back images-->
        </b-row>

        <!-- PanCard Module -->
        <!-- <b-row align-v="center">
            <h5>Pan Card Proof :-</h5>
            <b-col md="3">
                <div class="label_addhar_box">
                    <table>
                        <tr>
                            <td><label for="">PanCard No. :</label></td>
                            <td>
                                <label for="">{{ currentRowData.pan_card_number }}</label>
                            </td>
                        </tr>
                    </table>
                </div>
            </b-col>
        
            <b-col md="6">
                <div class="front_image">
                    <img v-b-modal.modal-1 @click="largeImg = currentRowData.pan_card_path" :src="currentRowData.pan_card_path" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
                </div>
            </b-col>
      
            <b-col md="3" v-if="currentRowData.is_pan_verify == 1">
                <span class="success">Approved</span>
            </b-col>
           
        </b-row> -->

        <b-row align-v="center">
            <b-col md="8"> </b-col>
            <b-col md="4">
                <b-button variant="danger" class="succ_btn" @click="onclose()">close</b-button>
            </b-col>
        </b-row>
    </b-modal>

    <!-- Large Image Modal -->
    <b-modal id="modal-1" size="lg" v-if="largeImg">
        <b-row align-v="center">
            <img :src="largeImg" loading="lazy" class="img-fluid img-thumbnail" alt="front_image" />
        </b-row>
        <template #modal-footer>
            <p></p>
        </template>
    </b-modal>
</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import ApiClass from "../../api/api";
import PageHeader from "@/components/page-header";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

import {
    required,
    requiredIf
} from "vuelidate/lib/validators";

export default {
    components: {
        Layout,
        PageHeader,
        DataTable,
        Column,
    },
    data() {
        return {
            totalRows: 1,
            per_page: 10,
            page: 1,
            title: "KYC VERIFICATION",
            items: [{
                    text: "Completed",
                    href: "#",
                },
                {
                    text: "KYC VERIFICATION",
                    active: true,
                },
            ],

            customers: [],
            loading: true,
            currentRowData: "",
            largeImg: "",
            remarkShow: false,
            remarkShow1: false,
            form: {
                I_remark: "", // Identity remark
                P_remark: "", // Pan Card remark
                idenity_status: "",
                pancard_status: "",
            },
            submitted: false,
        };
    },
    validations: {
        form: {
            idenity_status: {
                required
            },
            pancard_status: {
                required
            },
            I_remark: {
                required: requiredIf(function () {
                    return this.form.idenity_status == 0 ? true : false;
                }),
            },
            P_remark: {
                required: requiredIf(function () {
                    return this.form.pancard_status == 0 ? true : false;
                }),
            },
        },
    },
    mounted() {
        this.getKycdetails();
    },

    methods: {
        async getKycdetails() {
            await ApiClass.getRequest(
                "admin/userkyc/get?status=completed&per_page=" +
                this.per_page +
                "&page=" +
                this.page,
                true
            ).then((res) => {
                if (res.data.status_code == 1) {
                    this.loading = false;
                    this.customers = res.data.data.data;
                    // console.log("customers===", this.customers);
                    this.currentPage = res.data.data.current_page;
                    this.totalRows = res.data.data.total;
                }
            });
        },

        onclose() {
            this.$root.$emit("bv::hide::modal", "modal-center");
        },

        onPage(event) {
            if (this.page != event.page + 1) {
                this.loading = !this.loading;
                this.page = event.page + 1;
            }
            if (this.per_page != event.rows) {
                this.loading = !this.loading;
                this.per_page = event.rows;
            }
            this.getKycdetails();
        },
    },
};
</script>

<style lang="scss">
.p-paginator {
    .p-paginator-current {
        margin-left: auto;
    }
}

.p-progressbar {
    height: 0.5rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #00acad;
    }
}

.table-header {
    display: flex;
    justify-content: space-between;
}

.p-datepicker {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

.p-datatable.p-datatable-customers {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead>tr>th {
        text-align: left;
    }

    .p-datatable-tbody>tr>td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}

.p-calendar .p-datepicker {
    width: 350px;
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

@media screen and (max-width: 64em) {
    .p-datatable {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr>td {
                text-align: left;
                display: block;
                border: 0 none !important;
                width: 100% !important;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: 0.4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -0.4rem 1rem -0.4rem -0.4rem;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
